const cards = {
  cards: 'Certificat',
  search_cards: "Rechercher des cartes/Nom d'affichage",
  add_card: 'Nouveau certificat',
  add_mobile_pass: 'Nouveau forfait mobile',
  update_card: 'Certificat',
  numbers: 'Nombres',
  options: 'Options',
  general_information: 'Informations générales',
  assign_user: 'Attribuer un utilisateur',
  assigned_user: 'Utilisateur désigné',
  assign_msg1: 'Voulez-vous attribuer la carte ?',
  assign_msg2: 'pour',
  assign_reader1: 'Voulez-vous numériser avec le lecteur ?',
  assign_reader2: 'du site',
  unassign_user_msg1: 'Voulez-vous désallouer ?',
  unassign_user_msg2: 'de la carte',
  delete_card_multiple_sites:
    "REMARQUE: Cette carte est associée à plus d'un site, elle sera supprimée de tous.",
  readers: 'Lectrices',
  custom_number: 'Numéro personnalisé',
  scan_card: 'Scanner la carte',
  search_devices: 'Rechercher des appareils',
  scan_status: 'État de la numérisation',
  start_scan_msg:
    'Une carte va être créée, voulez-vous poursuivre le processus de numérisation ?',
  registration_copied: "Code d'enregistrement copié dans presse-papiers",
  send_to: 'Envier un',
  send_another: 'Enviar un autre',
  enrolled: 'Inscrite',
  re_enroll: 'Réinscrire',
  available: 'Disponible',
  discover: 'Découvrir KPROG',
  use_kprog: 'Utiliser KPROG',
  kprog: 'KPROG',
  table: {
    card: 'CERTIFICAT',
    format: 'FORMAT',
    name: 'AFFICHER UN NOM',
    state: 'ÉTAT',
    activation_date: "DATE ​​D'ACTIVATION",
    assigned: 'ATTRIBUÉ',
    stolen: 'VOLÉ',
    lost: 'PERDU',
    user_name: 'NOM',
    reader: 'LECTEUR',
    site: 'LIEU',
  },
  form: {
    individual: 'Individuel',
    group: 'Grappe',
    display_name: 'Afficher un nom',
    facility_code: "Code d'installation",
    card_code: 'Code Certificat',
    activation_date: "Date d'activation",
    expiry_date: "Date d'expiration",
    card_format: 'Format Certificat',
    card_number_hex: 'Numéro de carte hexadécimal',
    all: 'Tout',
    assigned: 'Attribué',
    unassigned: 'Non attribué',
    used_for_access: "Utilisé pour l'accès",
    stolen: 'Volé',
    lost: 'Perdu',
    status: 'État',
    active: 'Actif',
    programming_card: 'Certificat de programmation',
    registration_code: "Code d'enregistrement",
    select_reader: 'Sélectionnez un lecteur pour programmer le certificat',
    csn: 'C.S.N. (numéro imprimé)',
  },
  error: {
    invalid_email: "Format d'email invalide",
    activation_required: "La date d'activation est requise",
    activation_before: "La date doit être avant l'expiration",
    expiration_after: "La date doit être après l'activation",
    min_date: 'Date minimale: {{date}}',
    mobilePassLicExced:
      "Nous sommes désolés, mais il n'y a actuellement aucune licence disponible pour Mobile Pass.",
  },
  pin_option: {
    not_used: 'Non utilisé',
    start_lockdown: 'Démarrer le confinement',
    stop_lockdLock: 'Arrêtez le confinement',
    lockdown_grant: "Le verrouillage accorde l'accès",
    area_secured: 'Garder la porte sécurisée (maintenir le verrouillage)',
  },
};

export default cards;
