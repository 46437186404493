/*
 * Created by: Diego Rincón <drincon@binwus.com>
 * Created at: 2024-09-05
 *
 * Remarkable changes:
 * - 2024-09-10 / Diego Rincón <drincon@binwus.com>
 *   Refactor hook to use function overloading
 */

import { useMemo } from 'react';
import { ActionTag } from 'interfaces/permissions.interface';
import { useSelector } from '../store';

type CheckMode = 'and' | 'or';

type CheckPermissionFunction = (params: UsePermissionParams) => boolean;

/**
 * Custom hook to check user permissions.
 *
 * This hook can be used in three different ways:
 * 1. Without parameters: Returns a function to check permissions.
 * 2. With a single ActionTag: Checks permission for that specific action.
 * 3. With an object of type UsePermissionParams: Checks permissions based on provided parameters.
 *
 * @example
 * // Usage with UsePermissionParams
 * const canEditDoor = usePermission({
 *   actions: ['door-edit-basic', 'door-edit-advanced'],
 *   checkMode: 'or',
 *   extraCondition: someCondition
 * });
 *
 * @example
 * // Usage with a single ActionTag
 * const canAddDoor = usePermission('door-add');
 *
 * @example
 * // Usage without parameters. Only use this for more dynamic checks
 * const checkPermission = usePermission();
 * const canEditDoor = checkPermission({ actions: ['door-edit-basic', 'door-edit-advanced'], checkMode: 'or' });
 *
 *
 * @returns A boolean indicating if the user has the required permissions, or a function to check permissions.
 */

// Function overloading
function usePermission(): CheckPermissionFunction;
function usePermission(action: ActionTag): boolean;
function usePermission(params: UsePermissionParams): boolean;

function usePermission(
  actionOrParams?: ActionTag | UsePermissionParams,
): boolean | CheckPermissionFunction {
  const actionMap = useSelector((state) => state.user.actionMap);

  const checkPermission = useMemo(
    () =>
      (params: UsePermissionParams): boolean => {
        const { actions, checkMode = 'and', extraCondition = true } = params;
        const check = (tag: ActionTag) => actionMap[tag.toLowerCase()] ?? false;

        const hasPermission =
          checkMode === 'and' ? actions.every(check) : actions.some(check);

        return hasPermission && Boolean(extraCondition);
      },
    [actionMap],
  );

  // If no parameters are provided, return the function to check permissions
  if (typeof actionOrParams === 'undefined') {
    return checkPermission;
  }

  // If a single ActionTag is provided, check permission for that action
  if (typeof actionOrParams === 'string') {
    return checkPermission({ actions: [actionOrParams] });
  }

  // If an object is provided, check permissions based on the provided parameters
  return checkPermission(actionOrParams);
}

/**
 * Interface for the parameters of the usePermission hook when used with an object.
 */
export interface UsePermissionParams {
  /**
   * List of action tags to check.
   */
  actions: ActionTag[];

  /**
   * Mode to check permissions:
   * - 'and': Requires all actions to be permitted.
   * - 'or': Requires at least one action to be permitted.
   * @default 'and'
   */
  checkMode?: CheckMode;

  /**
   * An additional condition to be met.
   * @default true
   */
  extraCondition?: boolean;
}

export { usePermission };
