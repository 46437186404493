const support_tool = {
  // New session
  new_session: 'New support session',
  select_user_type: 'Select the type of user to give support',
  sent_code_to:
    'To continue, complete this verification step. We have sent a code to',
  please_enter: '. Please, enter it below.',
  account_success: 'Successfully created a session for account {{account}}',
  dealer_success: 'Successfully created a session for dealer user {{dealer}}',
  otp_verification: 'OTP Verification',
  new_account_session_confirmation:
    'Are you sure you want to create a new session for account {{account}}?',
  new_dealer_session_confirmation:
    'Are you sure you want to create a new session for dealer user {{dealer}}?',

  // Open sessions
  open_sessions: 'Open sessions',
  search_sessions: 'Search sessions',
  session_type: 'Session type',
  support_email: 'Support email',
  end_session: 'End session',
  end_session_confirmation: 'Are you sure you want to end the session?',
  session_ended: 'Session ended successfully',
  session_code: 'Session code',
  session_code_msg:
    'You are not the owner of this session. To open it, ask the owner for the session code and enter it below.',
  session_opened: 'Session opened successfully',

  // Session window
  select_account: 'Select an account',
  support_session: 'Support session',
  switch_account: 'Switch account',
  session_details: 'Session details',
  support_case: 'Support case',
  support_agent: 'Support agent',
  contact_name: 'Contact name',
  contact_number: 'Contact number',
  controller: 'Controller',
  sdk_entities: 'SDK Entities',
  unsynced: 'Unsynced',
  tasks: 'Tasks',
  no_session_msg:
    'There is no session currently open. Please, open a session to continue',

  // Diagnose Controller
  get_information: 'Get information',
  diagnose_controller: 'Diagnose - Controller',
  serial_number: 'Serial Number',
  last_alive: 'Last alive',
  status_ping: 'Status Ping',
  restore_global_certificate: 'Restore Global Certificate',
  create_aws_thing: 'Create AWS Thing',
  open_support_case: 'Open a support case',
  server_status: 'Server Status',
  frontend: 'Frontend',
  backend: 'Backend',
  database: 'Database',
  iot_services: 'IOT Services',
  controller_details: 'Controller Details',
  controller_status: 'Controller Status',

  // User types
  dealer: 'Dealer',
  account: 'Account',

  // Controller Tab
  site_name: 'Site Name',
  status: 'Status',
  sync_status: 'Sync Status',
  firmware_version: 'Firmware Version',
  online: 'Online',
  offline: 'Offline',
  synced: 'Synced',
  unknown: 'Unknown',
  sync: 'Sync',
  firmware: 'Firmware',
  sdk: 'SDK',
  syncing: 'Syncing...',
  checking: 'Checking...',
  sync_confirmation:
    'Are you sure you want to sync the controller {{controller}}? This action can take a few minutes.',
  sync_successfull: 'Controller synced successfully',

  // SDK Controller
  module_serial: 'Module Serial',
  ping: 'Ping',
  edit_cloud: 'Edit Cloud',
  object_type: 'Object Type',
  sub_module: 'Sub - Module',
  command: 'Command',
  record: 'Record',
  options: 'Options',
  command_tasks: 'Command Tasks',
  command_task_details: 'Command Task Details',
  entities_table: 'Entities Table',
  sending_command: 'Sending command...',
  open_cloud: 'Open Cloud',
  description: 'Description',
  tag: 'Tag',
  sync_cloud: 'Sync Cloud',
  sync_controller: 'Sync Controller',
  in_memory: 'In memory',
  id: 'ID',
  id_from: 'ID From',
  id_to: 'ID To',
  max: 'Max',
  no_command_task_selected: 'No command task selected',
  response: 'Response',
  request: 'Request',
  created_at: 'Created at',
  created: 'Created',
  started_at: 'Started at',
  started: 'Started',
  stop: 'Stop',
  pending: 'Pending',
  finished: 'Finished',
  type: 'Type',
  date: 'Date',
  entity: 'Entity',
  tag_name: 'Tag Name',
  cloud_data: 'Cloud Data',
  controller_data: 'Controller Data',

  // SDK Entities
  search_by_id: 'Search by ID',
  search_by_name: 'Search by Field Name',
  show_details: 'Show details',
  per_record: 'Per record',
  per_controller: 'Per controller',
  search: 'Search',
  from_id: 'From ID',
  to_id: 'To ID',
  field_name: 'Field Name',
  value: 'Value',
  label: 'Label',
  object_status: 'Object Status',
  view: 'View',
  push: 'Push',
  view_sdk: 'View SDK',
  save_push: 'Save & Push',
  entity_id: 'Entity ID',
  entity_sync: 'Entity Sync',
  entity_data: 'Entity Data',
  records: 'Records',
  copy: 'Copy',
  paste: 'Paste',
  view_entity: 'View entity',
  push_successfull: 'Entity data pushed successfully',
  pasted_data: 'Pasted Data',
  paste_confirmation: 'Are you sure you want to paste the following data?',
  paste_error: 'Error pasting data',
  copy_success: 'Data copied successfully',
  copy_error: 'Error copying data',

  // Unsynced Tab
  name: 'Name',
  read: 'Read',
  site: 'Site',
  sync_count_cloud: 'Sync Count Cloud',
  sync_count_controller: 'Sync Count Controller',
  sync_count: 'Sync Count',
};

export default support_tool;
