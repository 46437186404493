import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import user, { user_profiles } from 'interfaces/user.interface';
import { dateFormat } from '../../../../helpers/dateUtils';

interface Props {
  user: user;
  profiles: user_profiles[];
  children: React.ReactNode;
  switchProfile: (profile: user_profiles) => void;
}

export default function ProfileTable({
  user,
  profiles,
  children,
  switchProfile,
}: Readonly<Props>) {
  const { t } = useTranslation();

  return (
    <Box marginBottom={4}>
      {children}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('switch.name')}</TableCell>
              <TableCell>{t('switch.last_login')}</TableCell>
              <TableCell>{t('switch.actions')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {profiles.map((profile: user_profiles, i: number) => (
              <TableRow key={profile.dealer_org_id}>
                <TableCell>
                  <Typography
                    fontWeight={
                      profile.dealer_org_role_id === user.role
                        ? 'bold'
                        : 'normal'
                    }
                  >
                    {profile.company_org_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    fontWeight={
                      profile.dealer_org_role_id === user.role
                        ? 'bold'
                        : 'normal'
                    }
                  >
                    {profile.last_login ? dateFormat(profile.last_login) : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  {profile.dealer_org_role_id === user.role ? (
                    <Typography fontWeight={'bold'}>
                      {t('switch.active_profile')}
                    </Typography>
                  ) : (
                    <Link
                      id={`switch-profile-${profile?.profile_type}-${i}`}
                      href="#"
                      onClick={() => switchProfile(profile)}
                    >
                      {t('switch.login')}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
