import React, { useEffect, useState } from 'react';
import user from 'interfaces/user.interface';
import routes from '../../../routes/routes';
import { useDispatch, useSelector } from '../../../store';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSelectedSite } from '../../../store/actions/selected-site';
import { site } from 'interfaces/site.interface';
import Button from '../../../components/Button/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { OpenSession } from 'interfaces/supportTool.interface';
import { setUser } from '../../../store/actions/user';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/system';

const reports = [
  routes.report_cards,
  routes.report_events,
  routes.report_users,
];

const hardware = [
  routes.controller_list,
  routes.output_list,
  routes.input_list,
  routes.firmware_manager,
];

const settings = [
  routes.holiday_list,
  routes.schedule_list,
  routes.access_level,
  routes.lockdown,
];

const SelectionNavMini = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: user = useSelector((store) => store.user.user);
  const selectedSite: site = useSelector((store) => store.selectedSite.site);
  const session: OpenSession = useSelector(
    (store) => store.supportSession.session,
  );

  const [options, setOptions] = useState<any[]>([]);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const isSystemUser = user.profile_type === 'system' && !user.is_support;

  useEffect(() => {
    initOptions();
  }, []);

  useEffect(() => {
    initOptions();
  }, [user.selectedDealer, user.selectedOrg, selectedSite?.site, session]);

  const initOptions = () => {
    const opts = [];
    selectedSite?.site &&
      user.selectedOrg?.multi_site &&
      opts.push({ name: 'site', value: selectedSite });
    user.selectedOrg?.organization &&
      opts.push({ name: 'account', value: user.selectedOrg });
    user.selectedDealer?.dealer &&
      opts.push({ name: 'dealer', value: user.selectedDealer });
    reports.includes(pathname) &&
      opts.push({ name: 'report', value: { name: t('Reports') } });
    hardware.includes(pathname) &&
      opts.push({ name: 'hardware', value: { name: t('HARDWARE') } });
    settings.includes(pathname) &&
      opts.push({ name: 'setting', value: { name: t('SETTINGS') } });
    session &&
      opts.push({
        name: 'support-session',
        value: { name: t('supportTool.support_session') },
      });
    setOptions(opts);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goTo = () => {
    switch (user.profile_type) {
      case 'organization':
        return user.selectedOrg?.multi_site && selectedSite.site
          ? routes.site_dashboard
          : routes.home;

      default:
        return user.selectedOrg?.multi_site && selectedSite.site
          ? routes.site_dashboard
          : routes.organization_info;
    }
  };

  const navigateTo = (name: string) => {
    handleCloseUserMenu();
    switch (name) {
      case 'dealer':
        user.selectedOrg?.multi_site && dispatch(setSelectedSite(undefined));
        navigate(isSystemUser ? routes.organization_list : routes.home);
        break;
      case 'account':
        user.selectedOrg?.multi_site && dispatch(setSelectedSite(undefined));
        navigate(routes.organization_info);
        break;
      case 'site':
        navigate(routes.site_dashboard);
        break;
      case 'report':
        navigate(`${goTo()}?view=report`);
        break;
      case 'hardware':
        navigate(`${goTo()}?view=hardware`);
        break;
      case 'setting':
        navigate(`${goTo()}?view=setting`);
        break;
      case 'support-session':
        navigate(routes.support_current_session);
        break;
    }
  };

  const clearUser = () => {
    dispatch(setUser({ ...user, selectedDealer: null, selectedOrg: null }));
  };

  return (
    <>
      <Stack
        direction="row"
        alignContent="center"
        mt={0.8}
      >
        <Button
          color="transparent"
          paddingLeft="1em"
          paddingRight="1em"
          onClick={() => navigateTo(options[0]?.name)}
        >
          <Typography
            fontWeight={options.length <= 1 ? '999' : ''}
            component="span"
            variant="subtitle1"
            color="white"
            // color={options.length <= 1 ? "#414141" : "#616161"}
            fontSize={16}
          >
            {options[0]?.value.name}
          </Typography>
        </Button>
        {options.length > 1 && (
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ color: theme.palette.text.secondary }}
          >
            {anchorElUser ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </Stack>
      {options.length > 1 && (
        <Menu
          // sx={{ mt: "50px" }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {options.map(
            (e, index: number) =>
              index !== 0 && (
                <MenuItem
                  key={`option_${e.name}_${index}`}
                  onClick={() => navigateTo(e.name)}
                >
                  <Typography
                    fontWeight={index === options.length - 1 ? '999' : '100'}
                    component="span"
                    // color={index === options.length - 1 ? "#414141" : "#616161"}
                    variant="subtitle1"
                  >
                    {e.value.name}
                  </Typography>
                  {isSystemUser && e.name === 'dealer' && (
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        clearUser();
                      }}
                      sx={{ ml: 1 }}
                    >
                      <ClearIcon
                        fontSize="small"
                        color="action"
                      />
                    </IconButton>
                  )}
                </MenuItem>
              ),
          )}
        </Menu>
      )}
    </>
  );
};

export default SelectionNavMini;
