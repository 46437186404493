const general = {
  sites: 'Sitios',
  site: 'Sitio',
  door: 'Puerta',
  add_site: 'Nuevo Sitio',
  edit_site: 'Sitio',
  edit_site_access: 'Editar Acceso Sitio',
  search_site: 'Sitio de búsqueda',
  copy_organization: 'Copiar cuenta',
  delete_site_message: '¿Estás segura de que quieres eliminar {{site}}?',
  notifications: 'Notificaciones',
  notification: 'Notificación',
  events: 'Eventos',
  site_info: 'Información del sitio',
  total_sites_assigned: 'Total de sitios asignados',
  alerts: 'Alertas',
  add_notification: 'Nueva Notificación',
  send_to: 'Enviar a',
  delete_contact_message:
    '¿Estás seguro de que quieres eliminar el contacto {{contact}}?',
  invalid_email: 'Formato de correo electrónico inválido',
  select_unselect_all: 'Seleccionar/Deseleccionar Todo',
  notification_at_least_one: 'Selecciona al menos una notificación',
  form: {
    name: 'Nombre',
    address: 'Dirección',
    country: 'País',
    city: 'Ciudad',
    state: 'Expresar',
    zip_code: 'Código postal',
    timezone: 'Zona horaria',
    contact_to: 'Contactar con',
    type_email: 'Escribir correo electrónico',
    send_notification: 'Enviar notificación',
    lockdown: 'Bloqueo (iniciado, detenido)',
    alarm_forced: 'Puerta en alarma forzada',
    alarm_tampared: 'Puerta en alarma manipulada',
    tamper: 'Manosear',
    battery_troubles: 'Problemas de batería',
    power_troubles: 'Problemas de energía',
  },
  table: {
    name: 'NOMBRE',
    address: 'DIRECCIÓN',
    country: 'PAÍS',
    city: 'CIUDAD',
    state: 'EXPRESAR',
    zipcode: 'CÓDIGO POSTAL',
    timezone: 'ZONA HORARIA',
  },
  modal: {
    title: 'Eliminar Suscripción',
    current_subscription: 'Suscripción Actual',
    previous: 'Anterior',
    checkout: 'Checkout',
    change_card: 'Cambiar credencial',
    invoice_details: 'Detalles de Factura',
    current: 'Actual',
    request_processed: 'Tu solicitud ha sido procesada',
    print_receipt: 'Imprimir Recibo',
    download_receipt: 'Descargar Recibo',
    finish: 'Finalizar',
    due_today: 'Para Hoy',
    confirmation_description:
      'El pago no se puede modificar después de marcar esta ventana, ¿estás seguro de que quieres pagar?',
    total_amount: 'Monto Total',
    remove: 'Eliminar',
    confirmation: 'Confirmación',
    delete_payment_description: 'Está seguro de eliminar esta credencial',
  },
  door_added: 'puertas han sido agregadas',
  available_door_license: 'Licencias de puerta disponibles',
  confirm_door_activate:
    '¿Está seguro de que desea asignar {{doors}} licencias de puerta?',
  not_enough_doors: 'No tienes suficientes licencias de puertas disponibles',
  click_buy:
    'Haz clic en Comprar para agregar el resto de las puertas a tu plan',
  buy_door: 'Comprar {{doors}} licencias de puertas',
  buy_more_licenses: 'Comprar más licencias',
};

export default general;
