import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Avatar, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  changeUserSession,
  getRolePermissions,
  getSecurityRoleMenu,
} from '../../../api/api';
import avatar from '../../../assets/images/media/avatars/avatar1.png';
import Modal from '../../../components/Modal/Modal';
import { user_profiles } from 'interfaces/user.interface';
import { useDispatch, useSelector } from '../../../store';
import { changeProfile } from '../../../store/actions/user';
import ProfileTable from './ProfileTable/ProfileTable';
import routes from '../../../routes/routes';

type Props = {
  open: boolean;
  onClose: () => void;
  setMenu: (menu: any) => void;
};

export default function SwitchProfile({ open, onClose, setMenu }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store: any) => store.user.user);
  // Group profiles by profile.profile_type
  const profiles = user.user_profiles.reduce(
    (acc: any, profile: user_profiles) => {
      if (!acc[profile.profile_type]) {
        acc[profile.profile_type] = [];
      }
      acc[profile.profile_type].push(profile);
      return acc;
    },
    {},
  );

  const switchProfile = async (profile: user_profiles) => {
    try {
      const { data } = await getRolePermissions(profile.dealer_org_role_id);
      const { menus } = await getSecurityRoleMenu(profile.dealer_org_role_id);
      setMenu([...menus]);

      await changeUserSession(profile.dealer_org_role_id);

      dispatch(
        changeProfile(
          data,
          profile.dealer_org_role_id,
          profile.dealer_org_role_name,
          profile.dealer_org_id,
          profile.profile_type,
          null,
          profile.profile_type === 'dealer'
            ? {
                dealer: profile.dealer_org_id,
                name: profile.company_org_name,
                image: user.selectedDealer?.image,
              }
            : null,
        ),
      );

      onClose();
      navigate(routes.home);
    } catch (error: any) {
      console.error('Switch profile error:', error);
    }
  };

  return (
    <Modal
      width="md"
      open={open}
      onClose={onClose}
    >
      <Typography
        variant="h2"
        marginBottom={2}
      >
        {t('switch.welcome', { name: user.first_name })}
      </Typography>

      <Typography variant="h2">{t('switch.please_select')}</Typography>

      <Stack
        direction="row"
        spacing={2}
        marginTop={2}
      >
        <Avatar
          variant="rounded"
          sx={{ width: 100, height: 100 }}
        >
          <img
            src={avatar}
            alt={`${user.first_name} ${user.last_name}`}
            style={{ width: '100px', height: '100px' }}
          />
        </Avatar>

        <Box width={'100%'}>
          <Typography variant="h3">
            {user.first_name} {user.last_name}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            marginBottom={4}
          >
            <EmailIcon />
            <Typography variant="h4">{user.email}</Typography>
          </Stack>

          {Object.keys(profiles).map((key: string) => (
            <ProfileTable
              key={key}
              user={user}
              profiles={profiles[key]}
              switchProfile={switchProfile}
            >
              <Typography variant="h3">{t(`switch.as_${key}`)}</Typography>
            </ProfileTable>
          ))}
        </Box>
      </Stack>
    </Modal>
  );
}
