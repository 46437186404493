import { useEffect, useState } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import { useDispatch } from '../store';
import { removeUser, setUser } from '../store/actions/user';

import SplashScreen from '../components/SplashScreen/SplashScreen';

import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

import { useSession } from '../hooks/useSession';
import { useUser } from '../hooks/useUser';

const AppRoutes = () => {
  const location = useLocation();

  const session = useSession();
  const user = useUser();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  // useEffect for setting user's LocalStorage data in Redux store
  useEffect(() => {
    if (location.pathname.includes('activate_account')) {
      dispatch(removeUser());
    }

    if (!session && user?.is_support) {
      dispatch(setUser({ ...user, selectedOrg: null, selectedDealer: null }));
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <Routes>
      {user ? (
        <Route
          path="/*"
          element={<PrivateRoutes />}
        />
      ) : (
        <Route
          path="/*"
          element={<PublicRoutes />}
        />
      )}

      {/* Health check */}
      <Route
        path="/status"
        element={<div>Status: OK</div>}
      />
    </Routes>
  );
};

export default AppRoutes;
